import React from 'react'
import { graphql } from 'gatsby'
import parse from 'html-react-parser'
import GatsbyImage, { FluidObject } from 'gatsby-image'
import { Row, Col } from 'react-bootstrap'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faChevronDown } from '@fortawesome/free-solid-svg-icons'

import { PlayerPageQuery } from '../../types/graphql'

import {
  Blanket,
  BlanketTitle,
  BlanketSubtitle,
  BlanketContent,
} from '../../components/Blanket'
import { Container } from '../../components/Container'
import { FirstTeam } from '../../components/FirstTeam'
import { SEO } from '../../components/Seo'

import * as classes from './player.module.scss'

interface Props {
  data: PlayerPageQuery
}

export default ({ data }: Props) => (
  <Blanket dark={false} noPadding>
    <SEO title={data.wordpressWpGiocatore?.title!} />
    {data.wordpressWpGiocatore?.videoMd?.localFile?.publicURL && (
      <video
        width={'100%'}
        height={'auto'}
        autoPlay
        muted
        loop
        className={classes.videoMedium}
        crossOrigin='anonymous'
      >
        <source
          src={data.wordpressWpGiocatore.videoMd.localFile.publicURL}
          type='video/mp4'
        />
      </video>
    )}

    {data.wordpressWpGiocatore?.video?.localFile?.publicURL && (
      <video
        width={'100%'}
        height={'auto'}
        autoPlay
        muted
        loop
        className={classes.video}
        crossOrigin='anonymous'
      >
        <source
          src={data.wordpressWpGiocatore.video.localFile.publicURL}
          type='video/mp4'
        />
      </video>
    )}

    <Container>
      <BlanketTitle>{data.wordpressWpGiocatore?.title!}</BlanketTitle>

      <BlanketSubtitle>
        {data.wordpressWpGiocatore?.info?.Ruolo!}
      </BlanketSubtitle>

      <BlanketContent>
        <Row className={classes.playerInfo}>
          <Col xs={2} lg={2}>
            <div className={classes.redBar} />
          </Col>

          <Col xs={10} lg={5}>
            <GatsbyImage
              fluid={
                data.wordpressWpGiocatore?.featured_media?.localFile
                  ?.childImageSharp?.fluid as FluidObject
              }
              alt={data.wordpressWpGiocatore?.featured_media?.alt_text!}
              className={classes.playerImage}
            />
          </Col>

          <Col xs={12} lg={5}>
            <div className={classes.blackBox}>
              {data.wordpressWpGiocatore?.info && (
                <>
                  <p>Ruolo: {data.wordpressWpGiocatore?.info.Ruolo}</p>
                  <p>Numero: {data.wordpressWpGiocatore?.info.Numero}</p>
                  <p>
                    Nazionalità: {data.wordpressWpGiocatore?.info.Nazionalit_}
                  </p>
                  <p>
                    Luogo di nascita:{' '}
                    {data.wordpressWpGiocatore?.info.Luogo_di_nascita}
                  </p>
                  <p>
                    Data di nascita:{' '}
                    {data.wordpressWpGiocatore?.info.Data_di_nascita}
                  </p>
                  <p>Altezza: {data.wordpressWpGiocatore?.info.Altezza}</p>
                </>
              )}
            </div>
          </Col>
        </Row>
      </BlanketContent>
    </Container>

    <Container>
      {parse(data.wordpressWpGiocatore?.excerpt!)}

      <div className={classes.container}>
        <FontAwesomeIcon icon={faChevronDown} className={classes.icon} />
      </div>
    </Container>

    <Container>
      <BlanketContent>
        <div className={classes.container}>
          <BlanketTitle as={'h3'} className={classes.title}>
            Altri membri della squadra
          </BlanketTitle>

          <FirstTeam />
        </div>
      </BlanketContent>
    </Container>
  </Blanket>
)

export const query = graphql`
  query PlayerPage($playerId: Int!) {
    wordpressWpGiocatore(wordpress_id: { eq: $playerId }) {
      title
      slug
      excerpt
      info {
        Ruolo
        Numero
        Nazionalit_
        Luogo_di_nascita
        Data_di_nascita
        Altezza
      }
      featured_media {
        alt_text
        localFile {
          childImageSharp {
            fluid(maxWidth: 1080, maxHeight: 1080, quality: 100) {
              ...GatsbyImageSharpFluid
            }
          }
        }
      }
      video {
        localFile {
          publicURL
        }
      }
      videoMd {
        localFile {
          publicURL
        }
      }
    }
  }
`
